import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Header from './Header';
import Footer from './Footer';

import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(10),
    paddingTop: theme.spacing(10),
  },
  caption: {
    textAlign: "center",
    width: "100%",
  },
});

const License = props => {
  const { classes, user } = props;
  return (
    <React.Fragment>
      <Header user={user} login="/Login/target/license" />
        <Grid container direction="column"
    justify="flex-start"
    alignItems="flex-start" className={classes.root}>
          <Grid>
          <Typography variant="h4" gutterBottom>
            TERMS OF USE
          </Typography>
          <Typography variant="body1" gutterBottom>
          These terms govern your use of our website or services such as software that we include as part of the Services, including any applications, Content Files, scripts, instruction sets, and any related documentation (collectively “Software”). By using the Services or Software, you agree to these terms.
          </Typography>
          <Typography variant="h5" gutterBottom>
          1.Use of Service.
          </Typography>
          <Typography variant="body1" gutterBottom>
          Subject to your compliance with these terms and the law, you may access and use the Services.withSparks Project Intellectual Property. We (and our licensors) remain the sole owner of all right, title, and interest in the Services and Software. We reserve all rights not granted under these terms.
          </Typography>
          <Typography variant="h5" gutterBottom>
          2.User Conduct.
          </Typography>
          <Typography variant="body1" gutterBottom>
          You must not misuse the Services, Software, or content that we provide to you as part of the Services. For example, you must not:
          </Typography>
          <Typography variant="body1" gutterBottom>
          (a) translate, copy, reproduce, reverse engineer, disassemble, decompile, decrypt or otherwise derive source code, modify, host, stream, sublicense, or resell the Services, Software, or content;
          </Typography>
          <Typography variant="body1" gutterBottom>
          (b) enable or allow others to use the Service, Software, or content using your account information;
          </Typography>
          <Typography variant="body1" gutterBottom>
          (c) circumvent any access or use restrictions put into place to prevent certain uses of the Services;
          </Typography>
          <Typography variant="h5" gutterBottom>
          3.Information collect
          </Typography>
          <Typography variant="body1" gutterBottom>
          We collect information to provide better services to all of our users
          </Typography>
          <Typography variant="body1" gutterBottom>
          Log information
          </Typography>
          <Typography variant="body1" gutterBottom>
          When you use our software or view content provided by withSparks Project, we automatically collect and store certain information in server logs.
          </Typography>
          <Typography variant="body1" gutterBottom>
          Unique application numbers
          </Typography>
          <Typography variant="body1" gutterBottom>
          Certain services include a unique application number. This number and information about your installation (for example, the operating system type and application version number) may be sent to withSparks Project when you install or uninstall that service or when that service periodically contacts our servers, such as for automatic updates.
          </Typography>
          <Typography variant="body1" gutterBottom>
          Information we collect when you are signed in to our software, in addition to information we obtain about you from partners, may be associated with your account.
          </Typography>
          <Typography variant="h5" gutterBottom>
          4.Limitation of liability
          </Typography>
          <Typography variant="body1" gutterBottom>
          Under no circumstances and under no legal theory, whether tort (including negligence), contract, or otherwise, shall you, the initial developer, any other contributor, or any distributor of covered code, or any supplier of any of such parties, be liable to any person for any indirect, special, incidental, or consequential damages of any character including, without limitation, damages for loss of goodwill, work stoppage, computer failure or malfunction, or any and all other commercial damages or losses, even if such party shall have been informed of the possibility of such damages.
          </Typography>
          <Typography variant="h5" gutterBottom>
          5.Modification.
          </Typography>
          <Typography variant="body1" gutterBottom>
          We may modify these terms or any additional terms that apply to a Service or Software to, for example, reflect changes to the law or changes to our Services or Software. You should look at the terms regularly. We will post notice of modifications to these terms on this page. We will post notice of modified additional terms in the applicable Service or Software. By continuing to use or access the Services or Software after the revisions come into effect, you agree to be bound by the revised terms.
          </Typography>
          </Grid>
        </Grid>
        <Grid container direction="column"
    justify="flex-start"
    alignItems="flex-start" className={classes.root}>
          <Grid>
        <Typography variant="h4" gutterBottom>
          PRIVACY POLICY
        </Typography>
        <Typography variant="body1" gutterBottom>
        We recognize that personal information is an important information asset, and as one of our social responsibilities, we will strive to protect personal information in order to respect the privacy of the individual and use it effectively in our business activities.
        </Typography>
        <Typography variant="h5" gutterBottom>
        Compliance with laws, regulations, and other standards regarding the protection of personal information.
        </Typography>
        <Typography variant="body1" gutterBottom>
        We will comply with the laws and regulations regarding the protection of personal information in Japan, as well as guidelines and other norms established by the government.
        </Typography>
        <Typography variant="h5" gutterBottom>
        Ensuring the Accuracy and Security of Personal Information.
        </Typography>
        <Typography variant="body1" gutterBottom>
        In order to ensure the accuracy and security of personal information, the Company will endeavor to keep personal information accurate and up-to-date, and will take reasonable safety measures in terms of both information and communication technology and management organization systems to prevent unauthorized access to personal information and the loss, destruction, falsification, or leakage of personal information, in accordance with the risks of the personal information handled. We will also take corrective measures to prevent the loss, destruction, falsification, leakage, etc. of personal information.
        </Typography>
        <Typography variant="h5" gutterBottom>
        Response to complaints and consultations.
        </Typography>
        <Typography variant="body1" gutterBottom>
        The Company will accept complaints and consultations from the individual regarding the handling of personal information, and will take appropriate and prompt action.
        </Typography>
          </Grid>
        </Grid>
        <Grid container direction="column"
    justify="flex-start"
    alignItems="flex-start" className={classes.root}>
          <Grid>
            <Typography variant="h4" gutterBottom>
              CONTACT
            </Typography>
            <Typography variant="body1" gutterBottom>
            For inquiries, please contact us at the email address of our PayPal account, which will be displayed at the time of purchase.
            </Typography>
            <Typography variant="h5" gutterBottom>
            Publisher Corporation
            </Typography>
            <Typography variant="body1" gutterBottom>
            UNITED DESIGN OU<br/>
            Tallinn, Kesklinna linnaosa, Narva mnt 5<br/>
            Harju maakond 10117<br/>
            Estonia<br/>
            </Typography>
          </Grid>
        </Grid>
      <Footer/>
    </React.Fragment>
  );
}

License.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(License);
