import React, { useState, useEffect } from 'react';
//import { Route, BrowserRouter as Router } from 'react-router-dom';
import { BrowserRouter, Route, Switch ,Redirect} from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
//import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
//import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles'
import theme from './theme';
import Home from './Home';
import Faq from './Faq';
import License from './License';
// import Play from './Play';
import Login from './Login';
import Decoder from './Decoder';
import Custom_webrtc from './Custom_webrtc';
// import * as firebase from "firebase/app";
// import "firebase/firestore";
// import "firebase/storage";
// mport config from './config';

// firebase.initializeApp(config);
// const db = firebase.firestore();
// const strage = firebase.storage();

function App() {
  const [user, setUser] = useState(null);

  useEffect(()=>{
    // function script(path){
    //   const head = document.getElementsByTagName('head')[0];
    //   const scriptUrl = document.createElement('script');
    //   scriptUrl.type = 'text/javascript';
    //   scriptUrl.src = path;//'js/index.js';
    //   head.appendChild(scriptUrl);
    // }
    // script('js/index.js');
    // script('js/shader.js');

    // const unregisterAuthObserver = firebase.auth().onAuthStateChanged(
    //   async (user) => {
    //     setUser(user);
    //     if (user) {
    //       const refUser = db.collection("users").doc(user.uid);
    //       const newValue = { lastAccessed:firebase.firestore.FieldValue.serverTimestamp() };
    //       const doc = (await refUser.get()).data();
    //       if (!doc || !doc.name) {
    //         newValue.name = user.displayName;
    //       }
    //       await refUser.set(newValue, { merge: true });
    //     }
    //   }
    // );

    // return unregisterAuthObserver;
  }, []);

  const params = { }//user, db , strage};
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Route exact path="/" render={(props) => <Home {...props} {...params} />} />
        <Route exact path="/DesignPad" render={(props) => <Redirect to="/" />} />
        <Route exact path="/Home" render={(props) => <Home {...props} {...params} />} />
        <Route exact path="/Faq" render={(props) => <Faq {...props} {...params} />} />
        <Route exact path="/License" render={(props) => <License {...props} {...params} />} />
        <Route exact path="/Custom_webrtc/:id" render={(props) => <Custom_webrtc {...props} {...params} />} />

        {/*<Route exact path="/login" render={(props) => <Login {...props} {...params} />} />
        <Route exact path="/login/cmd/:encoded" render={(props) => <Login {...props} {...params} />} />
        <Route exact path="/login/target/:target" render={(props) => <Login {...props} {...params} />} />
        { // We need to mount the Decoder component only after the user info became available.
          (user) ?
            <Route exact path="/decode/:encoded" render={(props) => <Decoder {...props} {...params} />} />
            : ""
        }
        */}

      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
