import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Header from './Header';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(10),
  },
  caption: {
    textAlign: "center",
    width: "100%",
    padding: theme.spacing(3),
    paddingBottom:theme.spacing(5),
  },
});

const Footer = props => {
  const { classes, user } = props;
  return (
    <React.Fragment>
      <Typography className={classes.caption} variant="body2" gutterBottom>
        ©2021 All Rights Reserved.
      </Typography>
    </React.Fragment>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
